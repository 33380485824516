import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import styled, { CSSProperties, keyframes } from 'styled-components';

import { range } from 'lib/arrays';

const animate = keyframes`
 10% {
  opacity: 1;
 }
  to {
      transform: translateY(-100%)
  }
`;

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 60%;
  &.fullHeight {
    top: 0;
  }
  overflow: hidden;
  mask-image: linear-gradient(to bottom, transparent, white 80%);
  .emoji {
    position: absolute;
    display: flex;
    align-items: flex-end;
    height: 100%;
    left: var(--x);
    opacity: 0;
    transform: scale(var(--scale)) translateY(0);
    animation: ${animate} var(--animation-duration) var(--animation-iteration)
      linear;
    contain: content;
  }
`;

type Props = {
  emoji: ReactNode;
  fullHeight?: boolean;
  nbEmoji?: number;
  animationDuration?: number;
  animationDelayMs?: number;
  animationIteration?: number;
};
export const EmojiFlood = ({
  emoji,
  fullHeight,
  nbEmoji = 20,
  animationDuration = 1,
  animationDelayMs = 2000,
  animationIteration = Infinity,
}: Props) => {
  const [visible, setVisible] = useState(false);

  const [, start] = useTimeoutFn(() => {
    setVisible(true);
  }, animationDelayMs);

  start();

  return (
    <Wrapper className={classNames({ fullHeight })}>
      {visible &&
        range(nbEmoji).map(i => {
          const x = `${Math.random() * 100}%`;
          const scale = Math.random() * (4 - 1) + 1;
          const delay = `${Math.random()}s`;
          return (
            <div
              key={i}
              className="emoji"
              style={
                {
                  '--x': x,
                  '--scale': scale,
                  '--animation-duration': `${animationDuration}s`,
                  '--animation-iteration': `${animationIteration}`,
                  animationDelay: delay,
                } as CSSProperties
              }
            >
              {emoji}
            </div>
          );
        })}
    </Wrapper>
  );
};
