export const MLB_CLUB_SHOP = "/mlb/club-shop";
export const MLB_HOME = "/mlb/home";
export const MLB = "/mlb";
export const MLB_MARKET_COMMON_MARKET = "/mlb/market/common-market";
export const MLB_MARKET = "/mlb/market";
export const MLB_MY_CARDS_COLLECTION_COLLECTIONSLUG = "/mlb/my-cards/collection/:collectionSlug";
export const MLB_MY_CARDS_BOXES_TYPE = "/mlb/my-cards/boxes/:type";
export const MLB_MY_CARDS_CARDS = "/mlb/my-cards/cards";
export const MLB_MY_CARDS_COLLECTIONS = "/mlb/my-cards/collections";
export const MLB_MY_CARDS_ESSENCE_TYPE = "/mlb/my-cards/essence/:type";
export const MLB_MY_CARDS = "/mlb/my-cards";
export const MLB_MY_CARDS_VAULT = "/mlb/my-cards/vault";
export const MLB_MY_CLUB_SLUG_CARDS = "/mlb/my-club/:slug/cards";
export const MLB_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG = "/mlb/my-club/:slug/collections/:collectionSlug";
export const MLB_MY_CLUB_SLUG_HISTORY_FIXTURE = "/mlb/my-club/:slug/history/:fixture";
export const MLB_MY_CLUB_SLUG_HISTORY = "/mlb/my-club/:slug/history";
export const MLB_MY_CLUB_SLUG = "/mlb/my-club/:slug";
export const MLB_NO_CARD_ROUTE = "/mlb/no-card-route";
export const MLB_PLAY_EVENTTYPE_FIXTURE_COMPETITION_COMPETITION_TRCKGRP = "/mlb/play/:eventType/:fixture/competition/:competition/:trckGrp";
export const MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION = "/mlb/play/:eventType/:fixture/enter/:seasonality/:competition";
export const MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY = "/mlb/play/:eventType/:fixture/enter/:seasonality";
export const MLB_PLAY_EVENTTYPE_FIXTURE_REWARDS = "/mlb/play/:eventType/:fixture/rewards";
export const MLB_PLAY_EVENTTYPE_FIXTURE = "/mlb/play/:eventType/:fixture";
export const MLB_PLAY_EVENTTYPE = "/mlb/play/:eventType";
export const MLB_PLAY_COMMON_MARKET = "/mlb/play/common-market";
export const MLB_PLAY = "/mlb/play";
export const MLB_PLAY_LINEUPS_EVENTTYPE_FIXTURE = "/mlb/play/lineups/:eventType/:fixture";
export const MLB_PLAY_LINEUPS = "/mlb/play/lineups";
export const MLB_PLAY_LOBBY_EVENTTYPE_FIXTURE = "/mlb/play/lobby/:eventType/:fixture";
export const MLB_PLAY_LOBBY = "/mlb/play/lobby";
export const MLB_PLAY_PRO_FIXTURE_CHASE_USERGROUP = "/mlb/play/pro/:fixture/chase/:userGroup";
export const MLB_PLAY_PRO_FIXTURE_CHASE = "/mlb/play/pro/:fixture/chase";
export const MLB_PLAY_PRO_FIXTURE_GAMES = "/mlb/play/pro/:fixture/games";
export const MLB_PLAY_PRO_FIXTURE = "/mlb/play/pro/:fixture";
export const MLB_PLAY_PRO_FIXTURE_POSTSEASON_USERGROUP = "/mlb/play/pro/:fixture/postseason/:userGroup";
export const MLB_PLAY_PRO_FIXTURE_POSTSEASON = "/mlb/play/pro/:fixture/postseason";
export const MLB_PLAY_PRO = "/mlb/play/pro";
export const MLB_PLAY_REWARDS_FIXTURE = "/mlb/play/rewards/:fixture";
export const MLB_PLAYERS_SLUG = "/mlb/players/:slug";
export const MLB_REWARDS_FIXTURE = "/mlb/rewards/:fixture";
export const MLB_SCOUTING_CENTER = "/mlb/scouting-center";
export const MLB_TEAMS_SLUG = "/mlb/teams/:slug";
export const BLOG = "/blog";
export const FOOTBALL_CAMPAIGNS = "/football/campaigns";
export const FOOTBALL_CLUB_SHOP = "/football/club-shop";
export const FOOTBALL_CLUBS_SLUG = "/football/clubs/:slug";
export const FOOTBALL_COUNTRIES_COUNTRYCODE = "/football/countries/:countryCode";
export const FOOTBALL = "/football";
export const FOOTBALL_LEAGUES_SLUG = "/football/leagues/:slug";
export const FOOTBALL_MARKET = "/football/market";
export const FOOTBALL_MY_CARDS_COLLECTION_COLLECTIONSLUG = "/football/my-cards/collection/:collectionSlug";
export const FOOTBALL_MY_CARDS_BOXES_TYPE = "/football/my-cards/boxes/:type";
export const FOOTBALL_MY_CARDS_CARDS = "/football/my-cards/cards";
export const FOOTBALL_MY_CARDS_COLLECTIONS = "/football/my-cards/collections";
export const FOOTBALL_MY_CARDS_ESSENCE_TYPE = "/football/my-cards/essence/:type";
export const FOOTBALL_MY_CARDS = "/football/my-cards";
export const FOOTBALL_MY_CLUB_SLUG_CARDS = "/football/my-club/:slug/cards";
export const FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION = "/football/my-club/:slug/collections/:collection";
export const FOOTBALL_MY_CLUB_SLUG_HISTORY_FIXTURE = "/football/my-club/:slug/history/:fixture";
export const FOOTBALL_MY_CLUB_SLUG_HISTORY = "/football/my-club/:slug/history";
export const FOOTBALL_MY_CLUB_SLUG = "/football/my-club/:slug";
export const FOOTBALL_NO_CARD_ROUTE = "/football/no-card-route";
export const FOOTBALL_PLAY_INVENTORY = "/football/play/inventory";
export const FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE = "/football/play/inventory/revealable-cards/:type";
export const FOOTBALL_PLAY = "/football/play";
export const FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_COMPETITION_TRCKGRP = "/football/play/pro/:fixture/competition/:competition/:trckGrp";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION = "/football/play/pro/:fixture/enter/:seasonality/:competition";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY = "/football/play/pro/:fixture/enter/:seasonality";
export const FOOTBALL_PLAY_PRO_FIXTURE = "/football/play/pro/:fixture";
export const FOOTBALL_PLAY_PRO = "/football/play/pro";
export const FOOTBALL_PLAY_REWARDS_FIXTURE = "/football/play/rewards/:fixture";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG = "/football/play/rivals/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG = "/football/play/rivals/leaderboard/:seasonSlug/:userSlug";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG = "/football/play/rivals/leaderboard/:seasonSlug";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD = "/football/play/rivals/leaderboard";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_SQUADSLUG_SELECTEDSQUADSLUG_USERSLUG = "/football/play/rivals/squad-leaderboard/:seasonSlug/:squadSlug/:selectedSquadSlug/:userSlug";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_SQUADSLUG_SELECTEDSQUADSLUG = "/football/play/rivals/squad-leaderboard/:seasonSlug/:squadSlug/:selectedSquadSlug";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_SQUADSLUG = "/football/play/rivals/squad-leaderboard/:seasonSlug/:squadSlug";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD = "/football/play/rivals/squad-leaderboard";
export const FOOTBALL_PLAY_RIVALS = "/football/play/rivals";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG = "/football/play/rivals/my-lineups/:seasonSlug/:gameSlug";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG = "/football/play/rivals/my-lineups/:seasonSlug";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_CREATE = "/football/play/rivals/my-squad/create";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD = "/football/play/rivals/my-squad";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG = "/football/play/rivals/my-squad/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG = "/football/play/rivals/my-squad/leaderboard/:seasonSlug/:userSlug";
export const FOOTBALL_PLAY_RIVALS_SQUADS_INVITE_JOINSECRET = "/football/play/rivals/squads/invite/:joinSecret";
export const FOOTBALL_PLAYERS_SLUG = "/football/players/:slug";
export const FOOTBALL_REWARDS_FIXTURE = "/football/rewards/:fixture";
export const FOOTBALL_REWARDS_ESSENCE = "/football/rewards/essence";
export const FOOTBALL_SCOUTING_CENTER = "/football/scouting-center";
export const HELP = "/help";
export const NBA_HOME = "/nba/home";
export const NBA = "/nba";
export const NBA_MARKET_COMMON_MARKET = "/nba/market/common-market";
export const NBA_MARKET = "/nba/market";
export const NBA_MY_CARDS_COLLECTION_COLLECTIONSLUG = "/nba/my-cards/collection/:collectionSlug";
export const NBA_MY_CARDS_BOXES_TYPE = "/nba/my-cards/boxes/:type";
export const NBA_MY_CARDS_CARDS = "/nba/my-cards/cards";
export const NBA_MY_CARDS_COLLECTIONS = "/nba/my-cards/collections";
export const NBA_MY_CARDS_ESSENCE_TYPE = "/nba/my-cards/essence/:type";
export const NBA_MY_CARDS = "/nba/my-cards";
export const NBA_MY_CLUB_SLUG_CARDS = "/nba/my-club/:slug/cards";
export const NBA_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG = "/nba/my-club/:slug/collections/:collectionSlug";
export const NBA_MY_CLUB_SLUG_HISTORY_FIXTURE = "/nba/my-club/:slug/history/:fixture";
export const NBA_MY_CLUB_SLUG_HISTORY = "/nba/my-club/:slug/history";
export const NBA_MY_CLUB_SLUG = "/nba/my-club/:slug";
export const NBA_NO_CARD_ROUTE = "/nba/no-card-route";
export const NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION = "/nba/play/:eventType/:fixture/enter/:seasonality/:competition";
export const NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY = "/nba/play/:eventType/:fixture/enter/:seasonality";
export const NBA_PLAY_EVENTTYPE_FIXTURE_REWARDS = "/nba/play/:eventType/:fixture/rewards";
export const NBA_PLAY_EVENTTYPE_FIXTURE = "/nba/play/:eventType/:fixture";
export const NBA_PLAY_EVENTTYPE = "/nba/play/:eventType";
export const NBA_PLAY_CHASE_EVENTTYPE_FIXTURE_USERGROUP = "/nba/play/chase/:eventType/:fixture/:userGroup";
export const NBA_PLAY_CHASE_EVENTTYPE_FIXTURE = "/nba/play/chase/:eventType/:fixture";
export const NBA_PLAY_CHASE_EVENTTYPE = "/nba/play/chase/:eventType";
export const NBA_PLAY_CHASE = "/nba/play/chase";
export const NBA_PLAY_COMMON_MARKET = "/nba/play/common-market";
export const NBA_PLAY_INVENTORY = "/nba/play/inventory";
export const NBA_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE = "/nba/play/inventory/revealable-cards/:type";
export const NBA_PLAY = "/nba/play";
export const NBA_PLAY_LINEUPS_EVENTTYPE_FIXTURE = "/nba/play/lineups/:eventType/:fixture";
export const NBA_PLAY_LINEUPS = "/nba/play/lineups";
export const NBA_PLAY_LOBBY_EVENTTYPE_FIXTURE = "/nba/play/lobby/:eventType/:fixture";
export const NBA_PLAY_LOBBY = "/nba/play/lobby";
export const NBA_PLAYERS_SLUG = "/nba/players/:slug";
export const NBA_REWARDS_FIXTURE = "/nba/rewards/:fixture";
export const NBA_REWARDS_ESSENCE = "/nba/rewards/essence";
export const NBA_SCOUTING_CENTER = "/nba/scouting-center";
export const NBA_TEAMS_SLUG = "/nba/teams/:slug";
export const ACTIVITY = "/activity";
export const CONTENT_CREATORS = "/content-creators";
export const INVITE = "/invite";
export const LICENSED_PARTNERS = "/licensed-partners";
export const LP = "/lp";
export const MY_SORARE = "/my-sorare";
export const SETTINGS = "/settings";
export const MLB_ALL_STAR_COLLECTION_EVENT = "/mlb/all-star-collection-event";
export const MLB_CARDS_SLUG = "/mlb/cards/:slug";
export const MLB_CLUB_SHOP_MERCH = "/mlb/club-shop/merch";
export const MLB_CLUB_SHOP_MY_ITEMS = "/mlb/club-shop/my-items";
export const MLB_CLUB_SHOP_UTILITY = "/mlb/club-shop/utility";
export const MLB_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUPID = "/mlb/compose-team/:leaderboardSlug/:lineupId";
export const MLB_COMPOSE_TEAM_LEADERBOARDSLUG = "/mlb/compose-team/:leaderboardSlug";
export const MLB_GALLERY_SLUG = "/mlb/gallery/:slug";
export const MLB_HOWTOPLAY = "/mlb/howToPlay";
export const MLB_INVITE = "/mlb/invite";
export const MLB_MARKET_COMMON_MARKET_ADD_CARD = "/mlb/market/common-market/add-card";
export const MLB_MARKET_COMMON_MARKET_DROP_CARD = "/mlb/market/common-market/drop-card";
export const MLB_MARKET_INSTANT_BUY = "/mlb/market/instant-buy";
export const MLB_MARKET_PRIMARY = "/mlb/market/primary";
export const MLB_MARKET_SECONDARY_PLAYERSLUG_RARITY = "/mlb/market/secondary/:playerSlug/:rarity";
export const MLB_MARKET_SECONDARY = "/mlb/market/secondary";
export const MLB_MY_CARDS_COLLECTION_COLLECTIONSLUG_CARDS = "/mlb/my-cards/collection/:collectionSlug/cards";
export const MLB_MY_CARDS_COLLECTION_COLLECTIONSLUG_LEADERBOARD = "/mlb/my-cards/collection/:collectionSlug/leaderboard";
export const MLB_MY_CARDS_BOXES = "/mlb/my-cards/boxes";
export const MLB_MY_CARDS_CARDS_TYPE = "/mlb/my-cards/cards/:type";
export const MLB_MY_CARDS_COLLECTIONS_TYPE = "/mlb/my-cards/collections/:type";
export const MLB_MY_CARDS_ESSENCE = "/mlb/my-cards/essence";
export const MLB_MY_CARDS_VAULT_TYPE = "/mlb/my-cards/vault/:type";
export const MLB_MY_CLUB_SLUG_CARDS_TYPE = "/mlb/my-club/:slug/cards/:type";
export const MLB_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG_LEADERBOARD = "/mlb/my-club/:slug/collections/:collectionSlug/leaderboard";
export const MLB_MY_CLUB_SLUG_COLLECTIONS = "/mlb/my-club/:slug/collections";
export const MLB_MY_CLUB_SLUG_EDIT = "/mlb/my-club/:slug/edit";
export const MLB_MY_CLUB = "/mlb/my-club";
export const MLB_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_ACCEPT = "/mlb/no-card-route/:so5FixtureId/:so5LineupId/accept";
export const MLB_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CANCEL = "/mlb/no-card-route/:so5FixtureId/:so5LineupId/cancel";
export const MLB_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CONFIRM = "/mlb/no-card-route/:so5FixtureId/:so5LineupId/confirm";
export const MLB_NO_CARD_ROUTE_SO5FIXTUREID = "/mlb/no-card-route/:so5FixtureId";
export const MLB_ONBOARDING = "/mlb/onboarding";
export const MLB_PLAY_EVENTTYPE_FIXTURE_COMPETITION_COMPETITION_TRCKGRP_TRCK = "/mlb/play/:eventType/:fixture/competition/:competition/:trckGrp/:trck";
export const MLB_PLAY_EVENTTYPE_FIXTURE_COMPETITION_COMPETITION = "/mlb/play/:eventType/:fixture/competition/:competition";
export const MLB_PLAY_EVENTTYPE_FIXTURE_COMPETITION = "/mlb/play/:eventType/:fixture/competition";
export const MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES = "/mlb/play/:eventType/:fixture/enter/:seasonality/:competition/:trck/games";
export const MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK = "/mlb/play/:eventType/:fixture/enter/:seasonality/:competition/:trck";
export const MLB_PLAY_EVENTTYPE_FIXTURE_ENTER = "/mlb/play/:eventType/:fixture/enter";
export const MLB_PLAY_EVENTTYPE_FIXTURE_CONTENDER_CONTENDER = "/mlb/play/:eventType/:fixture/contender/:contender";
export const MLB_PLAY_EVENTTYPE_FIXTURE_GAME_CENTER = "/mlb/play/:eventType/:fixture/game-center";
export const MLB_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP = "/mlb/play/:eventType/compose/:leaderboard/lineup/:lineup";
export const MLB_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM = "/mlb/play/:eventType/compose/:leaderboard/managerTeam/:managerTeam";
export const MLB_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD = "/mlb/play/:eventType/compose/:leaderboard";
export const MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD = "/mlb/play/:eventType/leaderboard/:leaderboard";
export const MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = "/mlb/play/:eventType/leaderboard/:leaderboard/prize-pool/:contender";
export const MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL = "/mlb/play/:eventType/leaderboard/:leaderboard/prize-pool";
export const MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = "/mlb/play/:eventType/leaderboard/:leaderboard/rankings/:contender";
export const MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS = "/mlb/play/:eventType/leaderboard/:leaderboard/rankings";
export const MLB_PLAY_EVENTTYPE_LEADERBOARD = "/mlb/play/:eventType/leaderboard";
export const MLB_PLAY_EVENTTYPE_LINEUPS_ID = "/mlb/play/:eventType/lineups/:id";
export const MLB_PLAY_COMMON_MARKET_ADD_CARD = "/mlb/play/common-market/add-card";
export const MLB_PLAY_COMMON_MARKET_DROP_CARD = "/mlb/play/common-market/drop-card";
export const MLB_PLAY_PRO_FIXTURE_CHASE_USERGROUP_USER = "/mlb/play/pro/:fixture/chase/:userGroup/:user";
export const MLB_PLAY_PRO_FIXTURE_COMPETITIONS_LEADERBOARD = "/mlb/play/pro/:fixture/competitions/:leaderboard";
export const MLB_PLAY_PRO_FIXTURE_COMPETITIONS = "/mlb/play/pro/:fixture/competitions";
export const MLB_PLAY_PRO_FIXTURE_GAMES_ID = "/mlb/play/pro/:fixture/games/:id";
export const MLB_PLAY_PRO_FIXTURE_MY_LINEUPS_LINEUP = "/mlb/play/pro/:fixture/my-lineups/:lineup";
export const MLB_PLAY_PRO_FIXTURE_MY_LINEUPS = "/mlb/play/pro/:fixture/my-lineups";
export const MLB_PLAY_PRO_FIXTURE_POSTSEASON_USERGROUP_USER = "/mlb/play/pro/:fixture/postseason/:userGroup/:user";
export const MLB_PLAY_PRO_CHASE_USERGROUP = "/mlb/play/pro/chase/:userGroup";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD = "/mlb/play/pro/leaderboard/:leaderboard";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = "/mlb/play/pro/leaderboard/:leaderboard/prize-pool/:contender";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL = "/mlb/play/pro/leaderboard/:leaderboard/prize-pool";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = "/mlb/play/pro/leaderboard/:leaderboard/rankings/:contender";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS = "/mlb/play/pro/leaderboard/:leaderboard/rankings";
export const MLB_PLAY_PRO_LEADERBOARD = "/mlb/play/pro/leaderboard";
export const MLB_PLAY_PRO_LINEUPS_ID = "/mlb/play/pro/lineups/:id";
export const MLB_PLAY_REWARDS = "/mlb/play/rewards";
export const MLB_PLAYERS_SLUG_CARDS = "/mlb/players/:slug/cards";
export const MLB_PLAYERS_SLUG_GAMES = "/mlb/players/:slug/games";
export const MLB_PLAYERS_SLUG_TRANSACTIONS = "/mlb/players/:slug/transactions";
export const MLB_POSTSEASON_COLLECTION_EVENT = "/mlb/postseason-collection-event";
export const MLB_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY = "/mlb/rewards/:fixture/:league/:quality/:rarity";
export const MLB_SCOUTING_CENTER_SCORES_GAMEID = "/mlb/scouting-center/scores/:gameId";
export const MLB_SCOUTING_CENTER_SCORES = "/mlb/scouting-center/scores";
export const MLB_SCOUTING_CENTER_STATS = "/mlb/scouting-center/stats";
export const MLB_TEAMS_SLUG_CARDS = "/mlb/teams/:slug/cards";
export const MLB_TEAMS_SLUG_PLAYERS = "/mlb/teams/:slug/players";
export const BLOG_BLOG_SLUG = "/blog/blog/:slug";
export const BLOG_BLOG_CATEGORY_CATEGORY = "/blog/blog/category/:category";
export const BLOG_BLOG = "/blog/blog";
export const BLOG_FOOTBALL_BLOG_SLUG = "/blog/football/blog/:slug";
export const BLOG_FOOTBALL_BLOG_CATEGORY_CATEGORY = "/blog/football/blog/category/:category";
export const BLOG_FOOTBALL_BLOG = "/blog/football/blog";
export const BLOG_MLB_BLOG_SLUG = "/blog/mlb/blog/:slug";
export const BLOG_MLB_BLOG_CATEGORY_CATEGORY = "/blog/mlb/blog/category/:category";
export const BLOG_MLB_BLOG = "/blog/mlb/blog";
export const BLOG_NBA_BLOG_SLUG = "/blog/nba/blog/:slug";
export const BLOG_NBA_BLOG_CATEGORY_CATEGORY = "/blog/nba/blog/category/:category";
export const BLOG_NBA_BLOG = "/blog/nba/blog";
export const BLOG_P_SLUG = "/blog/p/:slug";
export const FOOTBALL_BUNDESLIGA = "/football/bundesliga";
export const FOOTBALL_BUNDLED_AUCTIONS_ID = "/football/bundled-auctions/:id";
export const FOOTBALL_CAMPAIGNS_SLUG = "/football/campaigns/:slug";
export const FOOTBALL_CARDS_SLUG = "/football/cards/:slug";
export const FOOTBALL_CLUB_SHOP_TAB = "/football/club-shop/:tab";
export const FOOTBALL_CLUBS_SLUG_CARDS = "/football/clubs/:slug/cards";
export const FOOTBALL_CLUBS_SLUG_FIXTURES = "/football/clubs/:slug/fixtures";
export const FOOTBALL_CLUBS_SLUG_STANDINGS = "/football/clubs/:slug/standings";
export const FOOTBALL_CLUBS_SLUG_TEAM = "/football/clubs/:slug/team";
export const FOOTBALL_COUNTRIES_COUNTRYCODE_CARDS = "/football/countries/:countryCode/cards";
export const FOOTBALL_COUNTRIES_COUNTRYCODE_FIXTURES = "/football/countries/:countryCode/fixtures";
export const FOOTBALL_COUNTRIES_COUNTRYCODE_TEAM = "/football/countries/:countryCode/team";
export const FOOTBALL_CRAFT_RARITY = "/football/craft/:rarity";
export const FOOTBALL_CRAFT_CONFIRMATION_CARDSLUG = "/football/craft/confirmation/:cardSlug";
export const FOOTBALL_ENABLE_NOTIFICATIONS = "/football/enable-notifications";
export const FOOTBALL_EPL = "/football/epl";
export const FOOTBALL_INVITE = "/football/invite";
export const FOOTBALL_LALIGA_SANTANDER = "/football/laliga-santander";
export const FOOTBALL_LALIGA = "/football/laliga";
export const FOOTBALL_LEAGUES_SLUG_CARDS = "/football/leagues/:slug/cards";
export const FOOTBALL_LEAGUES_SLUG_FIXTURES = "/football/leagues/:slug/fixtures";
export const FOOTBALL_LOAN = "/football/loan";
export const FOOTBALL_MAJOR_LEAGUE_SOCCER = "/football/major-league-soccer";
export const FOOTBALL_MARKET_COMMON_PACKS_GROUP = "/football/market/common-packs/:group";
export const FOOTBALL_MARKET_COMMON_PACKS = "/football/market/common-packs";
export const FOOTBALL_MARKET_GO_PRO = "/football/market/go-pro";
export const FOOTBALL_MARKET_INSTANT_BUY = "/football/market/instant-buy";
export const FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY = "/football/market/manager-sales/:playerSlug/:rarity";
export const FOOTBALL_MARKET_MANAGER_SALES = "/football/market/manager-sales";
export const FOOTBALL_MARKET_MOBILE_COMMON_PACKS = "/football/market/mobile-common-packs";
export const FOOTBALL_MARKET_MOBILE_INSTANT_BUY = "/football/market/mobile-instant-buy";
export const FOOTBALL_MARKET_MOBILE_NEW_SIGNINGS = "/football/market/mobile-new-signings";
export const FOOTBALL_MARKET_NEW_SIGNINGS = "/football/market/new-signings";
export const FOOTBALL_MINIGAMES_PVE_LEAGUE = "/football/minigames/pve/:league";
export const FOOTBALL_MLS = "/football/mls";
export const FOOTBALL_MY_CARDS_COLLECTION_COLLECTIONSLUG_CARDS = "/football/my-cards/collection/:collectionSlug/cards";
export const FOOTBALL_MY_CARDS_COLLECTION_COLLECTIONSLUG_LEADERBOARD = "/football/my-cards/collection/:collectionSlug/leaderboard";
export const FOOTBALL_MY_CARDS_BOXES = "/football/my-cards/boxes";
export const FOOTBALL_MY_CARDS_CARDS_TYPE = "/football/my-cards/cards/:type";
export const FOOTBALL_MY_CARDS_COLLECTIONS_TYPE = "/football/my-cards/collections/:type";
export const FOOTBALL_MY_CARDS_ESSENCE = "/football/my-cards/essence";
export const FOOTBALL_MY_CLUB_SLUG_CARDS_TYPE = "/football/my-club/:slug/cards/:type";
export const FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION_CARDS = "/football/my-club/:slug/collections/:collection/cards";
export const FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION_LEADERBOARD = "/football/my-club/:slug/collections/:collection/leaderboard";
export const FOOTBALL_MY_CLUB_SLUG_COLLECTIONS = "/football/my-club/:slug/collections";
export const FOOTBALL_MY_CLUB_SLUG_EDIT = "/football/my-club/:slug/edit";
export const FOOTBALL_MY_CLUB = "/football/my-club";
export const FOOTBALL_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_ACCEPT = "/football/no-card-route/:so5FixtureId/:so5LineupId/accept";
export const FOOTBALL_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CANCEL = "/football/no-card-route/:so5FixtureId/:so5LineupId/cancel";
export const FOOTBALL_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CONFIRM = "/football/no-card-route/:so5FixtureId/:so5LineupId/confirm";
export const FOOTBALL_NO_CARD_ROUTE_SO5FIXTUREID = "/football/no-card-route/:so5FixtureId";
export const FOOTBALL_PL = "/football/pl";
export const FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE_SEARCH = "/football/play/inventory/revealable-cards/:type/search";
export const FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_COMPETITION_TRCKGRP_TRCK = "/football/play/pro/:fixture/competition/:competition/:trckGrp/:trck";
export const FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_COMPETITION = "/football/play/pro/:fixture/competition/:competition";
export const FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION = "/football/play/pro/:fixture/competition";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES = "/football/play/pro/:fixture/enter/:seasonality/:competition/:trck/games";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK = "/football/play/pro/:fixture/enter/:seasonality/:competition/:trck";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER = "/football/play/pro/:fixture/enter";
export const FOOTBALL_PLAY_PRO_FIXTURE_CONTENDER_CONTENDER = "/football/play/pro/:fixture/contender/:contender";
export const FOOTBALL_PLAY_PRO_FIXTURE_MATCH_CENTER = "/football/play/pro/:fixture/match-center";
export const FOOTBALL_PLAY_PRO_COMPOSE_LEADERBOARD_LINEUP_LINEUP = "/football/play/pro/compose/:leaderboard/lineup/:lineup";
export const FOOTBALL_PLAY_PRO_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM = "/football/play/pro/compose/:leaderboard/managerTeam/:managerTeam";
export const FOOTBALL_PLAY_PRO_COMPOSE_LEADERBOARD = "/football/play/pro/compose/:leaderboard";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD = "/football/play/pro/leaderboard/:leaderboard";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = "/football/play/pro/leaderboard/:leaderboard/prize-pool/:contender";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL = "/football/play/pro/leaderboard/:leaderboard/prize-pool";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = "/football/play/pro/leaderboard/:leaderboard/rankings/:contender";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS = "/football/play/pro/leaderboard/:leaderboard/rankings";
export const FOOTBALL_PLAY_PRO_LEADERBOARD = "/football/play/pro/leaderboard";
export const FOOTBALL_PLAY_PRO_LINEUPS_ID = "/football/play/pro/lineups/:id";
export const FOOTBALL_PLAY_PRO_MY_LINEUPS_ID = "/football/play/pro/my-lineups/:id";
export const FOOTBALL_PLAY_PRO_ONBOARDING_CHOOSE_YOUR_LEVEL = "/football/play/pro/onboarding/choose-your-level";
export const FOOTBALL_PLAY_PRO_ONBOARDING_CLUB_SELECTION = "/football/play/pro/onboarding/club-selection";
export const FOOTBALL_PLAY_PRO_ONBOARDING_COMPOSE_SLUG = "/football/play/pro/onboarding/compose/:slug";
export const FOOTBALL_PLAY_PRO_ONBOARDING_COMPOSE = "/football/play/pro/onboarding/compose";
export const FOOTBALL_PLAY_PRO_ONBOARDING_OPEN_STARTER_PACK = "/football/play/pro/onboarding/open-starter-pack";
export const FOOTBALL_PLAY_PRO_ONBOARDING = "/football/play/pro/onboarding";
export const FOOTBALL_PLAY_REWARDS = "/football/play/rewards";
export const FOOTBALL_PLAY_RIVALS_INSTANT_BUY_SLUG = "/football/play/rivals/instant-buy/:slug";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_ARENA = "/football/play/rivals/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/arena";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_MATCHES = "/football/play/rivals/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/matches";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_SQUADSLUG_SELECTEDSQUADSLUG_USERSLUG_ARENA = "/football/play/rivals/squad-leaderboard/:seasonSlug/:squadSlug/:selectedSquadSlug/:userSlug/arena";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_SQUADSLUG_SELECTEDSQUADSLUG_USERSLUG_MATCHES = "/football/play/rivals/squad-leaderboard/:seasonSlug/:squadSlug/:selectedSquadSlug/:userSlug/matches";
export const FOOTBALL_PLAY_RIVALS_ARENA = "/football/play/rivals/arena";
export const FOOTBALL_PLAY_RIVALS_DRAFT_SLUG = "/football/play/rivals/draft/:slug";
export const FOOTBALL_PLAY_RIVALS_DRAFT_SUBSTITUTES_SLUG = "/football/play/rivals/draft/substitutes/:slug";
export const FOOTBALL_PLAY_RIVALS_GAMES_ID = "/football/play/rivals/games/:id";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_ARENA = "/football/play/rivals/my-lineups/:seasonSlug/:gameSlug/arena";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_LINEUPS = "/football/play/rivals/my-lineups/:seasonSlug/:gameSlug/lineups";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_MY_SQUAD = "/football/play/rivals/my-lineups/:seasonSlug/:gameSlug/my-squad";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS = "/football/play/rivals/my-lineups";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_ARENA = "/football/play/rivals/my-squad/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/arena";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_MATCHES = "/football/play/rivals/my-squad/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/matches";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG = "/football/play/rivals/my-squad/leaderboard/:seasonSlug";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD = "/football/play/rivals/my-squad/leaderboard";
export const FOOTBALL_PLAY_RIVALS_ONBOARDING_CLUB_SELECTION = "/football/play/rivals/onboarding/club-selection";
export const FOOTBALL_PLAY_RIVALS_ONBOARDING_GAME_SIMULATION_SLUG = "/football/play/rivals/onboarding/game-simulation/:slug";
export const FOOTBALL_PLAY_RIVALS_ONBOARDING = "/football/play/rivals/onboarding";
export const FOOTBALL_PLAY_RIVALS_SQUADS_INVITE_JOINSECRET_USERSLUG = "/football/play/rivals/squads/invite/:joinSecret/:userSlug";
export const FOOTBALL_PLAYERS_SLUG_CARDS = "/football/players/:slug/cards";
export const FOOTBALL_PLAYERS_SLUG_FIXTURES = "/football/players/:slug/fixtures";
export const FOOTBALL_PLAYERS_SLUG_TRANSACTIONS = "/football/players/:slug/transactions";
export const FOOTBALL_PREMIER_LEAGUE = "/football/premier-league";
export const FOOTBALL_REWARD_BOX_ID = "/football/reward-box/:id";
export const FOOTBALL_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY = "/football/rewards/:fixture/:league/:quality/:rarity";
export const FOOTBALL_REWARDS_ESSENCE_QUALITY_RARITY = "/football/rewards/essence/:quality/:rarity";
export const FOOTBALL_RIVALS_LEAGUE_PRIZE_POOL = "/football/rivals-league-prize-pool";
export const FOOTBALL_SCOUTING_CENTER_LEADERBOARDGROUP = "/football/scouting-center/:leaderboardGroup";
export const FOOTBALL_SCOUTING_CENTER_REGULAR_COMPETITION_SLUG = "/football/scouting-center/regular-competition/:slug";
export const FOOTBALL_SCOUTING_CENTER_SO5_COMPETITION_SLUG = "/football/scouting-center/so5-competition/:slug";
export const FOOTBALL_SERIE_A = "/football/serie-a";
export const HELP_A_ARTICLEID_SLUG = "/help/a/:articleId/:slug";
export const HELP_C_CATEGORYID_SLUG = "/help/c/:categoryId/:slug";
export const HELP_S_SECTIONID_SLUG = "/help/s/:sectionId/:slug";
export const NBA_CARDS_SLUG = "/nba/cards/:slug";
export const NBA_COMMON_MARKET = "/nba/common-market";
export const NBA_DAILY_CLAIM = "/nba/daily-claim";
export const NBA_ENABLE_NOTIFICATIONS = "/nba/enable-notifications";
export const NBA_GALLERY_SLUG = "/nba/gallery/:slug";
export const NBA_GAMES_GAMEID_EVENTTYPE = "/nba/games/:gameId/:eventType";
export const NBA_GAMES_GAMEID = "/nba/games/:gameId";
export const NBA_GAMES = "/nba/games";
export const NBA_INVITE = "/nba/invite";
export const NBA_MARKET_COMMON_MARKET_ADD_CARD = "/nba/market/common-market/add-card";
export const NBA_MARKET_COMMON_MARKET_DROP_CARD = "/nba/market/common-market/drop-card";
export const NBA_MARKET_INSTANT_BUY = "/nba/market/instant-buy";
export const NBA_MARKET_MOBILE_INSTANT_BUY = "/nba/market/mobile-instant-buy";
export const NBA_MARKET_PRIMARY = "/nba/market/primary";
export const NBA_MARKET_SECONDARY_PLAYERSLUG_RARITY = "/nba/market/secondary/:playerSlug/:rarity";
export const NBA_MARKET_SECONDARY = "/nba/market/secondary";
export const NBA_MINIGAMES_PVE_CONFERENCE = "/nba/minigames/pve/:conference";
export const NBA_MY_CARDS_COLLECTION_COLLECTIONSLUG_CARDS = "/nba/my-cards/collection/:collectionSlug/cards";
export const NBA_MY_CARDS_COLLECTION_COLLECTIONSLUG_LEADERBOARD = "/nba/my-cards/collection/:collectionSlug/leaderboard";
export const NBA_MY_CARDS_BOXES = "/nba/my-cards/boxes";
export const NBA_MY_CARDS_CARDS_TYPE = "/nba/my-cards/cards/:type";
export const NBA_MY_CARDS_COLLECTIONS_TYPE = "/nba/my-cards/collections/:type";
export const NBA_MY_CARDS_ESSENCE = "/nba/my-cards/essence";
export const NBA_MY_CLUB_SLUG_CARDS_TYPE = "/nba/my-club/:slug/cards/:type";
export const NBA_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG_LEADERBOARD = "/nba/my-club/:slug/collections/:collectionSlug/leaderboard";
export const NBA_MY_CLUB_SLUG_COLLECTIONS = "/nba/my-club/:slug/collections";
export const NBA_MY_CLUB = "/nba/my-club";
export const NBA_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_ACCEPT = "/nba/no-card-route/:so5FixtureId/:so5LineupId/accept";
export const NBA_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CANCEL = "/nba/no-card-route/:so5FixtureId/:so5LineupId/cancel";
export const NBA_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CONFIRM = "/nba/no-card-route/:so5FixtureId/:so5LineupId/confirm";
export const NBA_NO_CARD_ROUTE_SO5FIXTUREID = "/nba/no-card-route/:so5FixtureId";
export const NBA_ONBOARDING = "/nba/onboarding";
export const NBA_PARIS_2025 = "/nba/paris-2025";
export const NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES = "/nba/play/:eventType/:fixture/enter/:seasonality/:competition/:trck/games";
export const NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK = "/nba/play/:eventType/:fixture/enter/:seasonality/:competition/:trck";
export const NBA_PLAY_EVENTTYPE_FIXTURE_ENTER = "/nba/play/:eventType/:fixture/enter";
export const NBA_PLAY_EVENTTYPE_FIXTURE_CONTENDER_CONTENDER = "/nba/play/:eventType/:fixture/contender/:contender";
export const NBA_PLAY_EVENTTYPE_FIXTURE_GAME_CENTER = "/nba/play/:eventType/:fixture/game-center";
export const NBA_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP = "/nba/play/:eventType/compose/:leaderboard/lineup/:lineup";
export const NBA_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM = "/nba/play/:eventType/compose/:leaderboard/managerTeam/:managerTeam";
export const NBA_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD = "/nba/play/:eventType/compose/:leaderboard";
export const NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD = "/nba/play/:eventType/leaderboard/:leaderboard";
export const NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = "/nba/play/:eventType/leaderboard/:leaderboard/prize-pool/:contender";
export const NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL = "/nba/play/:eventType/leaderboard/:leaderboard/prize-pool";
export const NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = "/nba/play/:eventType/leaderboard/:leaderboard/rankings/:contender";
export const NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS = "/nba/play/:eventType/leaderboard/:leaderboard/rankings";
export const NBA_PLAY_EVENTTYPE_LEADERBOARD = "/nba/play/:eventType/leaderboard";
export const NBA_PLAY_EVENTTYPE_LINEUPS_ID = "/nba/play/:eventType/lineups/:id";
export const NBA_PLAY_CHASE_EVENTTYPE_FIXTURE_USERGROUP_USER = "/nba/play/chase/:eventType/:fixture/:userGroup/:user";
export const NBA_PLAY_COMMON_MARKET_ADD_CARD = "/nba/play/common-market/add-card";
export const NBA_PLAY_COMMON_MARKET_DROP_CARD = "/nba/play/common-market/drop-card";
export const NBA_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE_SEARCH = "/nba/play/inventory/revealable-cards/:type/search";
export const NBA_PLAYERS_SLUG_CARDS = "/nba/players/:slug/cards";
export const NBA_PLAYERS_SLUG_GAMES = "/nba/players/:slug/games";
export const NBA_PLAYERS_SLUG_TRANSACTIONS = "/nba/players/:slug/transactions";
export const NBA_REWARD_BOX_ID = "/nba/reward-box/:id";
export const NBA_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY = "/nba/rewards/:fixture/:league/:quality/:rarity";
export const NBA_REWARDS_ESSENCE_QUALITY_RARITY = "/nba/rewards/essence/:quality/:rarity";
export const NBA_SCORES_GAMEID = "/nba/scores/:gameId";
export const NBA_SCORES = "/nba/scores";
export const NBA_SCOUTING_CENTER_SCORES_GAMEID = "/nba/scouting-center/scores/:gameId";
export const NBA_SCOUTING_CENTER_SCORES = "/nba/scouting-center/scores";
export const NBA_SCOUTING_CENTER_STATS = "/nba/scouting-center/stats";
export const NBA_TEAMS_SLUG_CARDS = "/nba/teams/:slug/cards";
export const NBA_TEAMS_SLUG_PLAYERS = "/nba/teams/:slug/players";
export const ACTIVITY_NEWS_ID = "/activity/news/:id";
export const ACTIVITY_NEWS = "/activity/news";
export const ADMIN_LEADERBOARD_LEADERBOARD_REWARDS = "/admin/leaderboard/:leaderboard/rewards";
export const AFFILIATE_PROGRAM = "/affiliate-program";
export const CAREERS = "/careers";
export const CLAIM_STRK = "/claim-strk";
export const CONFIRM_DEVICE = "/confirm-device";
export const CONFIRM_EMAIL = "/confirm-email";
export const COOKIE_POLICY = "/cookie-policy";
export const COVERAGE = "/coverage";
export const CREATOR_PROGRAM = "/creator-program";
export const DEBUG_DEVICE = "/debug-device";
export const DEEPLINK = "/deeplink";
export const FAQ = "/faq";
export const GAME_RULES = "/game-rules";
export const LEGAL_NOTICE = "/legal-notice";
export const LICENSED_PARTNERS_SPORT_TAB = "/licensed-partners/:sport/:tab";
export const LICENSED_PARTNERS_SPORT = "/licensed-partners/:sport";
export const MARKETPLACE_TERMS = "/marketplace-terms";
export const MOBILE_FORGOT_PASSWORD = "/mobile-forgot-password";
export const MOBILE_SIGN_UP = "/mobile-sign-up";
export const MY_SORARE_AUCTIONS = "/my-sorare/auctions";
export const MY_SORARE_FOLLOWS = "/my-sorare/follows";
export const MY_SORARE_INSTANT_BUYS = "/my-sorare/instant-buys";
export const MY_SORARE_NEW = "/my-sorare/new";
export const MY_SORARE_OFFERS_RECEIVED_ID = "/my-sorare/offers-received/:id";
export const MY_SORARE_OFFERS_RECEIVED = "/my-sorare/offers-received";
export const MY_SORARE_OFFERS_SENT_ID = "/my-sorare/offers-sent/:id";
export const MY_SORARE_OFFERS_SENT = "/my-sorare/offers-sent";
export const MY_SORARE_PLAYERS_NOTIFICATIONS = "/my-sorare/players-notifications";
export const MY_SORARE_PURCHASES = "/my-sorare/purchases";
export const MY_SORARE_SALES = "/my-sorare/sales";
export const MY_SORARE_TRANSACTIONS = "/my-sorare/transactions";
export const NETWORK_SLUG = "/network/:slug";
export const NEWSTART = "/newstart";
export const OAUTH_AUTHORIZE = "/oauth/authorize";
export const LANDING = "/";
export const PRESS = "/press";
export const PRIVACY_POLICY = "/privacy-policy";
export const PROMO_CLAIM_CODE = "/promo/claim/:code";
export const PROMO_SIGNUP_CODE = "/promo/signup/:code";
export const R_USERSLUG = "/r/:userSlug";
export const SETTINGS_ACCOUNT = "/settings/account";
export const SETTINGS_GLOBAL_PREFERENCES = "/settings/global-preferences";
export const SETTINGS_NOTIFICATIONS = "/settings/notifications";
export const SETTINGS_PAYMENT = "/settings/payment";
export const SETTINGS_PRIVACY_AND_SAFETY = "/settings/privacy-and-safety";
export const SETTINGS_SECURITY = "/settings/security";
export const SORARE50 = "/sorare50";
export const SPECIAL_CREDITS = "/special-credits";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const VERIFY_IDENTITY_ID = "/verify-identity/:id";
export const ANY_SPORT_CLUB_SHOP = "/club-shop";
export const ANY_SPORT_HOME = "/home";
export const ANY_SPORT_MARKET_COMMON_MARKET = "/market/common-market";
export const ANY_SPORT_MARKET = "/market";
export const ANY_SPORT_MY_CARDS_COLLECTION_COLLECTIONSLUG = "/my-cards/collection/:collectionSlug";
export const ANY_SPORT_MY_CARDS_BOXES_TYPE = "/my-cards/boxes/:type";
export const ANY_SPORT_MY_CARDS_CARDS = "/my-cards/cards";
export const ANY_SPORT_MY_CARDS_COLLECTIONS = "/my-cards/collections";
export const ANY_SPORT_MY_CARDS_ESSENCE_TYPE = "/my-cards/essence/:type";
export const ANY_SPORT_MY_CARDS = "/my-cards";
export const ANY_SPORT_MY_CLUB_SLUG_CARDS = "/my-club/:slug/cards";
export const ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG = "/my-club/:slug/collections/:collectionSlug";
export const ANY_SPORT_MY_CLUB_SLUG_HISTORY_FIXTURE = "/my-club/:slug/history/:fixture";
export const ANY_SPORT_MY_CLUB_SLUG_HISTORY = "/my-club/:slug/history";
export const ANY_SPORT_MY_CLUB_SLUG = "/my-club/:slug";
export const ANY_SPORT_NO_CARD_ROUTE = "/no-card-route";
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION = "/play/:eventType/:fixture/enter/:seasonality/:competition";
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY = "/play/:eventType/:fixture/enter/:seasonality";
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_REWARDS = "/play/:eventType/:fixture/rewards";
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE = "/play/:eventType/:fixture";
export const ANY_SPORT_PLAY_EVENTTYPE = "/play/:eventType";
export const ANY_SPORT_PLAY_COMMON_MARKET = "/play/common-market";
export const ANY_SPORT_PLAY = "/play";
export const ANY_SPORT_PLAY_LINEUPS_EVENTTYPE_FIXTURE = "/play/lineups/:eventType/:fixture";
export const ANY_SPORT_PLAY_LINEUPS = "/play/lineups";
export const ANY_SPORT_PLAY_LOBBY_EVENTTYPE_FIXTURE = "/play/lobby/:eventType/:fixture";
export const ANY_SPORT_PLAY_LOBBY = "/play/lobby";
export const ANY_SPORT_PLAY_PRO_FIXTURE = "/play/pro/:fixture";
export const ANY_SPORT_PLAY_PRO = "/play/pro";
export const ANY_SPORT_PLAY_REWARDS_FIXTURE = "/play/rewards/:fixture";
export const ANY_SPORT_PLAYERS_SLUG = "/players/:slug";
export const ANY_SPORT_REWARDS_FIXTURE = "/rewards/:fixture";
export const ANY_SPORT_SCOUTING_CENTER = "/scouting-center";
export const ANY_SPORT_TEAMS_SLUG = "/teams/:slug";
export const ANY_SPORT_PLAY_INVENTORY = "/play/inventory";
export const ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE = "/play/inventory/revealable-cards/:type";
export const ANY_SPORT_REWARDS_ESSENCE = "/rewards/essence";
export const ANY_SPORT_CARDS_SLUG = "/cards/:slug";
export const ANY_SPORT_GALLERY_SLUG = "/gallery/:slug";
export const ANY_SPORT_INVITE = "/invite";
export const ANY_SPORT_MARKET_COMMON_MARKET_ADD_CARD = "/market/common-market/add-card";
export const ANY_SPORT_MARKET_COMMON_MARKET_DROP_CARD = "/market/common-market/drop-card";
export const ANY_SPORT_MARKET_INSTANT_BUY = "/market/instant-buy";
export const ANY_SPORT_MARKET_PRIMARY = "/market/primary";
export const ANY_SPORT_MARKET_SECONDARY_PLAYERSLUG_RARITY = "/market/secondary/:playerSlug/:rarity";
export const ANY_SPORT_MARKET_SECONDARY = "/market/secondary";
export const ANY_SPORT_MY_CARDS_COLLECTION_COLLECTIONSLUG_CARDS = "/my-cards/collection/:collectionSlug/cards";
export const ANY_SPORT_MY_CARDS_COLLECTION_COLLECTIONSLUG_LEADERBOARD = "/my-cards/collection/:collectionSlug/leaderboard";
export const ANY_SPORT_MY_CARDS_BOXES = "/my-cards/boxes";
export const ANY_SPORT_MY_CARDS_CARDS_TYPE = "/my-cards/cards/:type";
export const ANY_SPORT_MY_CARDS_COLLECTIONS_TYPE = "/my-cards/collections/:type";
export const ANY_SPORT_MY_CARDS_ESSENCE = "/my-cards/essence";
export const ANY_SPORT_MY_CLUB_SLUG_CARDS_TYPE = "/my-club/:slug/cards/:type";
export const ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG_LEADERBOARD = "/my-club/:slug/collections/:collectionSlug/leaderboard";
export const ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS = "/my-club/:slug/collections";
export const ANY_SPORT_MY_CLUB_SLUG_EDIT = "/my-club/:slug/edit";
export const ANY_SPORT_MY_CLUB = "/my-club";
export const ANY_SPORT_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_ACCEPT = "/no-card-route/:so5FixtureId/:so5LineupId/accept";
export const ANY_SPORT_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CANCEL = "/no-card-route/:so5FixtureId/:so5LineupId/cancel";
export const ANY_SPORT_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CONFIRM = "/no-card-route/:so5FixtureId/:so5LineupId/confirm";
export const ANY_SPORT_NO_CARD_ROUTE_SO5FIXTUREID = "/no-card-route/:so5FixtureId";
export const ANY_SPORT_ONBOARDING = "/onboarding";
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES = "/play/:eventType/:fixture/enter/:seasonality/:competition/:trck/games";
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK = "/play/:eventType/:fixture/enter/:seasonality/:competition/:trck";
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER = "/play/:eventType/:fixture/enter";
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_CONTENDER_CONTENDER = "/play/:eventType/:fixture/contender/:contender";
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_GAME_CENTER = "/play/:eventType/:fixture/game-center";
export const ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP = "/play/:eventType/compose/:leaderboard/lineup/:lineup";
export const ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM = "/play/:eventType/compose/:leaderboard/managerTeam/:managerTeam";
export const ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD = "/play/:eventType/compose/:leaderboard";
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD = "/play/:eventType/leaderboard/:leaderboard";
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = "/play/:eventType/leaderboard/:leaderboard/prize-pool/:contender";
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL = "/play/:eventType/leaderboard/:leaderboard/prize-pool";
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = "/play/:eventType/leaderboard/:leaderboard/rankings/:contender";
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS = "/play/:eventType/leaderboard/:leaderboard/rankings";
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD = "/play/:eventType/leaderboard";
export const ANY_SPORT_PLAY_EVENTTYPE_LINEUPS_ID = "/play/:eventType/lineups/:id";
export const ANY_SPORT_PLAY_COMMON_MARKET_ADD_CARD = "/play/common-market/add-card";
export const ANY_SPORT_PLAY_COMMON_MARKET_DROP_CARD = "/play/common-market/drop-card";
export const ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD = "/play/pro/leaderboard/:leaderboard";
export const ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = "/play/pro/leaderboard/:leaderboard/prize-pool/:contender";
export const ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL = "/play/pro/leaderboard/:leaderboard/prize-pool";
export const ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = "/play/pro/leaderboard/:leaderboard/rankings/:contender";
export const ANY_SPORT_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS = "/play/pro/leaderboard/:leaderboard/rankings";
export const ANY_SPORT_PLAY_PRO_LEADERBOARD = "/play/pro/leaderboard";
export const ANY_SPORT_PLAY_PRO_LINEUPS_ID = "/play/pro/lineups/:id";
export const ANY_SPORT_PLAY_REWARDS = "/play/rewards";
export const ANY_SPORT_PLAYERS_SLUG_CARDS = "/players/:slug/cards";
export const ANY_SPORT_PLAYERS_SLUG_GAMES = "/players/:slug/games";
export const ANY_SPORT_PLAYERS_SLUG_TRANSACTIONS = "/players/:slug/transactions";
export const ANY_SPORT_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY = "/rewards/:fixture/:league/:quality/:rarity";
export const ANY_SPORT_SCOUTING_CENTER_SCORES_GAMEID = "/scouting-center/scores/:gameId";
export const ANY_SPORT_SCOUTING_CENTER_SCORES = "/scouting-center/scores";
export const ANY_SPORT_SCOUTING_CENTER_STATS = "/scouting-center/stats";
export const ANY_SPORT_TEAMS_SLUG_CARDS = "/teams/:slug/cards";
export const ANY_SPORT_TEAMS_SLUG_PLAYERS = "/teams/:slug/players";
export const ANY_SPORT_ENABLE_NOTIFICATIONS = "/enable-notifications";
export const ANY_SPORT_MARKET_MOBILE_INSTANT_BUY = "/market/mobile-instant-buy";
export const ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE_SEARCH = "/play/inventory/revealable-cards/:type/search";
export const ANY_SPORT_REWARD_BOX_ID = "/reward-box/:id";
export const ANY_SPORT_REWARDS_ESSENCE_QUALITY_RARITY = "/rewards/essence/:quality/:rarity";
