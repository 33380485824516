import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { FormattedMessage, FormattedTime } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Container } from 'atoms/layout/Container';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { HomeLink } from 'atoms/navigation/HomeLink';
import { BodyM } from 'atoms/typography';
import { notifications } from 'contexts/snackNotification';

const MaintenanceHeader = styled(Horizontal)`
  padding: var(--double-unit);
  background: var(--c-nd-100-opaque);
  border: 1px var(--c-nd-200-opaque) solid;
  margin: var(--double-unit) 0;
  border-radius: var(--double-unit);
`;
const Root = styled(Vertical)`
  padding: var(--double-unit);
`;

type Props = {
  startDate: Date;
  endDate: Date;
};

export const Maintenance = ({ startDate, endDate }: Props) => {
  return (
    <>
      <Helmet>
        {/* https://developers.google.com/search/blog/2011/01/how-to-deal-with-planned-site-downtime */}
        <meta name="prerender-status-code" content="503" />
      </Helmet>
      <Container>
        <Root>
          <HomeLink />
          <MaintenanceHeader>
            <FontAwesomeIcon icon={faWarning} color="var(--c-yellow-500)" />
            <BodyM as="p">
              <FormattedMessage
                {...notifications.serviceUnderMaintenance}
                values={{
                  start: (
                    <FormattedTime
                      value={startDate}
                      day="numeric"
                      month="short"
                      year="numeric"
                    />
                  ),
                  end: (
                    <FormattedTime
                      value={endDate}
                      day="numeric"
                      month="short"
                      year="numeric"
                    />
                  ),
                  msg: '',
                }}
              />
            </BodyM>
          </MaintenanceHeader>
        </Root>
      </Container>
    </>
  );
};
