import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

type Props = {
  fill?: Color;
  title?: string;
  hideBorders?: boolean;
  size?: keyof typeof unitMapping;
} & React.JSX.IntrinsicElements['svg'];
export const Relegation = ({
  fill = 'var(--c-rivals-loss)',
  title,
  hideBorders,
  size = 3,
  ...rest
}: Props) => {
  return (
    <svg
      viewBox="0 0 22 23"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      style={{ height: unitMapping[size] }}
      {...rest}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8092 10.2734L10.9998 16.0631L5.19046 10.2734L3.58564 11.8783L10.9998 19.293L18.4141 11.8783L16.8092 10.2734ZM16.8092 4.27297L10.9998 10.0627L5.19046 4.27297L3.58564 5.8779L10.9998 13.2926L18.4141 5.8779L16.8092 4.27297Z"
        fill={fill}
      />
      {!hideBorders && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0001 19.2931L3.58591 11.8784L5.19067 10.2735L11.0001 16.0632L16.8095 10.2734L18.4143 11.8784L11.0001 19.2931ZM18.2427 8.87818L21.2427 11.8784L11.0001 22.1216L0.757576 11.8784L3.75758 8.87818L0.757576 5.87799L5.1883 1.44699L11.0001 7.23915L16.812 1.44699L21.2427 5.87799L18.2427 8.87818ZM11.0001 13.2927L3.58591 5.87799L5.19059 4.27321L11 10.0627L16.8095 4.27307L18.4143 5.87799L11.0001 13.2927Z"
          fill="#212121"
        />
      )}
    </svg>
  );
};
