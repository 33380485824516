import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled, { CSSProperties } from 'styled-components';

import { AveragePlayerScore } from '__generated__/globalTypes';
import { ConditionalWrapper } from 'atoms/layout/ConditionalWrapper';
import { Vertical } from 'atoms/layout/flex';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { LabelXS } from 'atoms/typography';
import { useScreenSize } from 'hooks/device/useScreenSize';
import { laptopAndAbove } from 'style/mediaQuery';

import { PlayerAppearance_anyPlayer } from './__generated__/index.graphql';

const Progress = styled.div`
  width: 100%;
  height: 2px;
  border-radius: var(--unit);
  background: var(--bg-color);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: calc(100% - var(--progress));
    background: var(--progress-color);
    border-radius: var(--double-unit);
  }
`;

const FixWidth = styled(Vertical).attrs({ center: true })`
  width: var(--triple-unit);
  @media ${laptopAndAbove} {
    width: var(--quadruple-unit);
  }
`;

type AllowedAveragePlayerScore =
  | AveragePlayerScore.LAST_FIVE_SO5_AVERAGE_SCORE
  | AveragePlayerScore.LAST_FIFTEEN_SO5_AVERAGE_SCORE
  | AveragePlayerScore.LAST_TEN_PLAYED_SO5_AVERAGE_SCORE;
type Props = {
  player: PlayerAppearance_anyPlayer;
  mode: AllowedAveragePlayerScore;

  withTooltip?: boolean;
};

const count = {
  [AveragePlayerScore.LAST_FIFTEEN_SO5_AVERAGE_SCORE]: 15,
  [AveragePlayerScore.LAST_TEN_PLAYED_SO5_AVERAGE_SCORE]: 10,
  [AveragePlayerScore.LAST_FIVE_SO5_AVERAGE_SCORE]: 5,
};

export const PlayerAppearance = ({
  player,
  mode,
  withTooltip = false,
}: Props) => {
  const { up: isLaptopOrAbove } = useScreenSize('laptop');
  const appearances = {
    LAST_TEN_PLAYED_SO5_AVERAGE_SCORE: (player.lastTenSo5Appearances || 0) / 10,
    LAST_FIVE_SO5_AVERAGE_SCORE: (player.lastFiveSo5Appearances || 0) / 5,
    LAST_FIFTEEN_SO5_AVERAGE_SCORE:
      (player.lastFifteenSo5Appearances || 0) / 15,
  }[mode];

  return (
    <ConditionalWrapper
      wrap={withTooltip}
      Wrapper={(p: { children: ReactNode }) => (
        <Tooltip
          title={
            <FormattedMessage
              id="averagePlayerScoreCell.appearancesTooltip"
              defaultMessage="{appearances, number, percent} of games played over of the last {count}"
              values={{
                appearances,
                count: count[mode],
              }}
            />
          }
        >
          {p.children}
        </Tooltip>
      )}
    >
      <FixWidth gap={isLaptopOrAbove ? 0.5 : 0}>
        <LabelXS color="var(--c-nd-600)">
          <FormattedNumber value={appearances} style="percent" />
        </LabelXS>
        <Progress
          style={
            {
              '--bg-color': 'var(--c-nd-200)',
              '--progress-color': 'var(--c-brand-300)',
              '--progress': `${appearances * 100}%`,
            } as CSSProperties
          }
        />
      </FixWidth>
    </ConditionalWrapper>
  );
};

PlayerAppearance.fragments = {
  anyPlayer: gql`
    fragment PlayerAppearance_anyPlayer on AnyPlayerInterface {
      slug
      lastFiveSo5Appearances
      lastTenSo5Appearances
      lastFifteenSo5Appearances
    }
  ` as TypedDocumentNode<PlayerAppearance_anyPlayer>,
};
