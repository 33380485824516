import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { Horizontal } from 'atoms/layout/flex';
import { unitMapping } from 'lib/style';

const Root = styled(ButtonBase)`
  width: 100%;
  min-width: 120px;
  max-width: 140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: var(--c-black);
  border-radius: var(--unit);
  gap: 1px;

  --footer-bg-color: var(--c-nd-100);
  &:hover {
    --footer-bg-color: var(--c-nd-150);
  }
`;
const Top = styled(Horizontal)`
  display: grid;
  grid-template-columns: 1fr var(--quadruple-unit) 1fr;
  grid-template-rows: var(--triple-unit);
  background-color: var(--footer-bg-color);
  border-top-left-radius: var(--unit);
  border-top-right-radius: var(--unit);

  transition: background-color 0.3s ease-in-out;

  &:only-child {
    border-bottom-left-radius: var(--unit);
    border-bottom-right-radius: var(--unit);
  }
`;
const ScoreWrapper = styled.div`
  margin-top: var(--half-unit);
`;
const Matchups = styled(Horizontal).attrs({ gap: 0 })`
  height: var(--double-and-a-half-unit);
  background-color: var(--footer-bg-color);

  transition: background-color 0.3s ease-in-out;

  &:last-child {
    overflow: hidden;
    border-bottom-left-radius: var(--unit);
    border-bottom-right-radius: var(--unit);
  }
`;

type Props = {
  children: ReactNode;
  left: ReactNode;
  right: ReactNode;
  matchups?: ReactNode;
  extra?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  topGap?: keyof typeof unitMapping;
};

export const CardFooter = ({
  children,
  left,
  right,
  matchups,
  extra,
  onClick,
  topGap = 0,
}: Props) => {
  return (
    <Root onClick={onClick}>
      <Top gap={topGap}>
        <Horizontal center>{left}</Horizontal>
        <ScoreWrapper>{children}</ScoreWrapper>
        <Horizontal center>{right}</Horizontal>
      </Top>
      {matchups && <Matchups>{matchups}</Matchups>}
      {extra}
    </Root>
  );
};
