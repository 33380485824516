import { isFuture } from 'date-fns';

import { Tradeable } from '__generated__/globalTypes';

import { BANK_APPROVAL_ERROR, WALLET_ACCESS_ERROR } from '../constants/errors';

export const isTransferable = (card: { tradeableStatus: Tradeable }) =>
  card.tradeableStatus === Tradeable.YES;

export const isSettlementDelayed = (card: {
  tokenOwner?: {
    settleAt?: Date | null;
  } | null;
}) => card?.tokenOwner?.settleAt && isFuture(card.tokenOwner.settleAt);

const handledErrors = [BANK_APPROVAL_ERROR, WALLET_ACCESS_ERROR];

export const generateDealId = () =>
  window.crypto.getRandomValues(new Uint32Array(4)).join('');

export const isHandledError = (e: Error) => handledErrors.includes(e.name);
