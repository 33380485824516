import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';

import { catchAll } from '@sorare/routing';

import { Sport } from '__generated__/globalTypes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { API_ROOT } from '../config';
import {
  CAREERS,
  CONFIRM_DEVICE,
  CONFIRM_EMAIL,
  COOKIE_POLICY,
  DEBUG_DEVICE,
  FOOTBALL,
  FOOTBALL_MARKET_INSTANT_BUY,
  FOOTBALL_MARKET_MANAGER_SALES,
  FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY,
  FOOTBALL_MARKET_NEW_SIGNINGS,
  FOOTBALL_MY_CLUB_SLUG,
  FOOTBALL_PLAY,
  GAME_RULES,
  INVITE,
  LANDING,
  LICENSED_PARTNERS,
  LP,
  MLB,
  MLB_HOME,
  MLB_MARKET_INSTANT_BUY,
  MLB_MARKET_PRIMARY,
  MLB_MARKET_SECONDARY,
  MLB_MARKET_SECONDARY_PLAYERSLUG_RARITY,
  MLB_MY_CLUB_SLUG,
  MLB_PLAY,
  MOBILE_SIGN_UP,
  NBA,
  NBA_MY_CLUB_SLUG,
  NBA_PLAY,
  PRESS,
  PRIVACY_POLICY,
  R_USERSLUG,
  SETTINGS,
  TERMS_AND_CONDITIONS,
} from './__generated__/routes';

export { INVITE } from './__generated__/routes';

export const FOOTBALL_PATH = '/football';
export const MLB_PATH = '/mlb';
export const NBA_PATH = '/nba';

export const MY_SORARE_HOME = '/my-sorare';
export const MY_SORARE_WILDCARD = `${MY_SORARE_HOME}/*`;

export const ANY_SPORT_DRAFT = '/draft';
export const ANY_SPORT_SCORES = `/scores`;
export const ANY_SPORT_MARKET_NEW_SIGNINGS = `/market/new-signings`;
export const ANY_SPORT_MARKET_INSTANT_BUY = `/market/instant-buy`;
export const ANY_US_SPORTS_TEAM_SHOW = `/teams/:slug`;
export const ANY_SPORT_PLAY_EVENTTYPE = '/play/:eventType';
export const ANY_SPORT_PLAY_EVENTTYPE_LINEUPS_ID = `${ANY_SPORT_PLAY_EVENTTYPE}/lineups/:id`;

export const ANY_SPORT_LINEUPS_EVENTTYPE_FIXTURE = `/play/lineups/:eventType/:fixture`;

export const ANY_SPORT_CLUB_SHOP_INVENTORY = `/club-shop/inventory`;

export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE = `${ANY_SPORT_PLAY_EVENTTYPE}/:fixture`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_REWARDS = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE}/rewards`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_MATCH_CENTER = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE}/match-center`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE}/enter`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER}/:seasonality/:competition`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION}/:trck`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK}/games`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE}/competition`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_COMPETITION = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION}/:competition`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_COMPETITION_TRCKGRP = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_COMPETITION}/:trckGrp`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_COMPETITION_TRCKGRP_TRCK = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_COMPETITION_TRCKGRP}/:trck`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_CONTENDER_CONTENDER = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE}/:competition/contender/:contender`;
export const ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_USERGROUP_USER = `${ANY_SPORT_PLAY_EVENTTYPE_FIXTURE}/:userGroup/:user`;
export const ANY_SPORT_PLAY_EVENTTYPE_MY_LINEUPS_LINEUP = `${ANY_SPORT_PLAY_EVENTTYPE}/my-lineups/:lineup`;
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL = `${ANY_SPORT_PLAY_EVENTTYPE}/leaderboard/:leaderboard/prize-pool`;
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = `${ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL}/:contender`;
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS = `${ANY_SPORT_PLAY_EVENTTYPE}/leaderboard/:leaderboard/rankings`;
export const ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = `${ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS}/:contender`;
export const ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD = `${ANY_SPORT_PLAY_EVENTTYPE}/compose/$leaderboard`;
export const ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP = `${ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD}/lineup/$lineup`;
export const ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM = `${ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD}/managerTeam/$managerTeam`;

export const ANY_SPORT_SCOUTING_CENTER_LEADERBOARDGROUP = `/scouting-center/:leaderboardGroup`;
export const ANY_SPORT_COMMON_MARKET = `/play/common-market`;
export const ANY_SPORT_COMMON_MARKET_DROP_CARD = `${ANY_SPORT_COMMON_MARKET}/drop-card`;
export const ANY_SPORT_COMMON_MARKET_ADD_CARD = `${ANY_SPORT_COMMON_MARKET}/add-card`;

export const LEGACY_PLAYER_SHOW = `/players/:slug`;
export const LEGACY_PLAYER_SHOW_CARDS = `/players/:slug/cards`;

export const LEGACY_COUNTRY_SHOW = `/countries/:slug`;
export const LEGACY_BUNDLED_AUCTION = `/bundled-auctions/:id`;

const BLOG_WILDCARD = '/blog/*';
export const BLOG_PATH = '/blog';
export const ANY_SPORT_BLOG = '/:sport/blog/';

export const HELP = '/help';
export const HELP_NEW_REQUEST = '/help#new-request';
const HELP_WILDCARD = '/help/*';

export const FOOTBALL_CLUB_SHOW = `${FOOTBALL_PATH}/clubs/:slug`;
export const FOOTBALL_CLUB_SHOW_WILDCARD = `${FOOTBALL_CLUB_SHOW}/*`;
export const FOOTBALL_CLUB_SHOW_STANDINGS = `${FOOTBALL_CLUB_SHOW}/standings`;
export const FOOTBALL_CLUB_SHOW_FIXTURES = `${FOOTBALL_CLUB_SHOW}/fixtures`;
export const FOOTBALL_CLUB_SHOW_TEAM = `${FOOTBALL_CLUB_SHOW}/team`;
export const FOOTBALL_CLUB_SHOW_CARDS = `${FOOTBALL_CLUB_SHOW}/cards`;

export const FOOTBALL_LEAGUE_SHOW = `${FOOTBALL_PATH}/leagues/:slug`;
export const FOOTBALL_LEAGUE_SHOW_WILDCARD = `${FOOTBALL_LEAGUE_SHOW}/*`;
export const FOOTBALL_LEAGUE_SHOW_FIXTURES = `${FOOTBALL_LEAGUE_SHOW}/fixtures`;
export const FOOTBALL_LEAGUE_SHOW_CARDS = `${FOOTBALL_LEAGUE_SHOW}/cards`;

export const FOOTBALL_COUNTRY_SHOW = `${FOOTBALL_PATH}/countries/:countryCode`;
export const FOOTBALL_COUNTRY_SHOW_CARDS = `${FOOTBALL_COUNTRY_SHOW}/cards`;
export const FOOTBALL_COUNTRY_SHOW_FIXTURES = `${FOOTBALL_COUNTRY_SHOW}/fixtures`;
export const FOOTBALL_COUNTRY_SHOW_TEAM = `${FOOTBALL_COUNTRY_SHOW}/team`;

export const FOOTBALL_PLAYER_SHOW = `${FOOTBALL_PATH}${LEGACY_PLAYER_SHOW}`;
export const FOOTBALL_PLAYER_SHOW_WILDCARD = `${FOOTBALL_PLAYER_SHOW}/*`;
export const FOOTBALL_PLAYER_SHOW_FIXTURES = `${FOOTBALL_PATH}${LEGACY_PLAYER_SHOW}/fixtures`;
export const FOOTBALL_PLAYER_SHOW_CARDS = `${FOOTBALL_PLAYER_SHOW}/cards`;

export const TOKEN_AUCTION = `${FOOTBALL_PATH}/auctions/:id`;
const ACTIVITY_WILDCARD = '/activity/*';
export const OAUTH_AUTORIZE = '/oauth/authorize';

export const LANDING_PREVIEW_ROOT = '/lp/preview';

export const RENDERING_CARD_FACTORY_CARD_SAMPLE_PICTURE = `/rendering/card-factory/card-sample-pictures/:id/:serialNumber`;
export const RENDERING_CARD_FACTORY_CARDS_PICTURE_NORMAL = `/rendering/card-factory/cards/:id/normal`;
export const RENDERING_CARD_FACTORY_CARD_SAMPLE_PICTURE_NORMAL = `/rendering/card-factory/card-sample-pictures/:id/:serialNumber/normal`;
export const RENDERING_CARD_FACTORY_CARD_PICTURE = `/rendering/card-factory/cards/:id`;
export const RENDERING_FOOTBALL_SO5_LINEUP = '/rendering/football/lineups/:id';
export const RENDERING_BASEBALL_SO5_LINEUP = '/rendering/baseball/lineups/:id';
export const RENDERING_CARD_COLLECTION = `/rendering/card-collections/:slug/:userSlug`;
export const RENDERING_FOOTBALL_MANAGER_ID = `/rendering/football/manager-id/:slug`;
export const RENDERING_NBA_MANAGER_ID = `/rendering/nba/manager-id/:slug`;
export const RENDERING_BASEBALL_MANAGER_ID = `/rendering/baseball/manager-id/:slug`;
export const RENDERING_FOOTBALL_GALLERY = `/rendering/football/gallery/:slug`;
export const RENDERING_NBA_GALLERY = `/rendering/nba/gallery/:slug`;
export const RENDERING_BASEBALL_GALLERY = `/rendering/baseball/gallery/:slug`;
export const RENDERING_FOOTBALL_DECK = `/rendering/football/:slug/decks/:deckSlug`;
export const RENDERING_NBA_DECK = `/rendering/nba/:slug/decks/:deckSlug`;
export const RENDERING_BASEBALL_DECK = `/rendering/baseball/:slug/decks/:deckSlug`;
export const RENDERING_FOOTBALL_RIVALS_INVITE = `/rendering/football/rivals/:userSlug/invites/:slug`;
export const RENDERING_FOOTBALL_RIVALS_CHALLENGE = `/rendering/football/rivals/challenge/:id/:userSlug`;
export const RENDERING_FOOTBALL_RIVALS_SQUAD_INVITE = `/rendering/football/rivals/squad/:joinSecret`;

export const FOOTBALL_HOME = FOOTBALL_PATH;

export const FOOTBALL_RIVALS = `${FOOTBALL_PATH}/rivals`;

const FOOTBALL_PLAY_RIVALS = `${FOOTBALL_PATH}/play/rivals`;
export const FOOTBALL_PLAY_RIVALS_WILDCARD = `${FOOTBALL_PLAY_RIVALS}/*`;

export enum PrivateLeaguesStep {
  CREATE = 'create',
  CREATE_FORM = 'create-form',
  CONGRATS = 'congrats',
}
export enum PrivateLeaguesTab {
  TEAMS = 'teams',
  LEADERBOARD = 'leaderboard',
  LEAGUE = 'league',
  MEMBERS = 'members',
  PRIZE_POOL = 'prize-pool',
}

export const MLB_HOW_TO_PLAY_EXTERNAL = `https://mlbguide.sorare.com`;

export const NBA_HOME = '/nba';
const NBA_WILDCARD = `${NBA_PATH}/*`;
export const NBA_MARKET = `${NBA_PATH}/market`;
export const NBA_CARDS_SLUG = `${NBA_PATH}/cards/:slug`;

export const NBA_PRIMARY_MARKET = `${NBA_MARKET}/primary`;
export const NBA_INSTANT_BUY_MARKET = `${NBA_MARKET}/instant-buy`;
export const NBA_SECONDARY_MARKET = `${NBA_MARKET}/secondary`;
export const NBA_SECONDARY_MARKET_STACK_SHOW = `${NBA_SECONDARY_MARKET}/:playerSlug/:rarity`;

export const NBA_TEAM = `${NBA_PATH}${ANY_US_SPORTS_TEAM_SHOW}`;

export const NBA_ONBOARDING = `${NBA_PATH}/onboarding`;

const NBA_LOBBY = `${NBA_PATH}/lobby`;

export const NBA_LOBBY_WILDCARD = `${NBA_LOBBY}/*`;

export const MLB_PLAY_LOBBY_EVENTTYPE = `${MLB_PATH}/play/lobby/:eventType`;
export const MLB_PLAY_LINEUPS_EVENTTYPE = `${MLB_PATH}/play/lineups/:eventType`;

export const NBA_PLAY_LOBBY_EVENTTYPE = `${NBA_PATH}/play/lobby/:eventType`;
export const NBA_PLAY_LINEUPS_EVENTTYPE = `${NBA_PATH}/play/lineups/:eventType`;

export const DEFAULT_SEASON_SLUG = 'current';

export const SECONDARY_MARKET_STACK_SHOW_BY_SPORT: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY,
  [Sport.NBA]: NBA_SECONDARY_MARKET_STACK_SHOW,
  [Sport.BASEBALL]: MLB_MARKET_SECONDARY_PLAYERSLUG_RARITY,
};

export const AUCTION_MARKET_URL: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_MARKET_NEW_SIGNINGS,
  [Sport.BASEBALL]: MLB_MARKET_PRIMARY,
  [Sport.NBA]: NBA_PRIMARY_MARKET,
};

export const SECONDARY_MARKET_URL_BY_SPORT: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_MARKET_MANAGER_SALES,
  [Sport.BASEBALL]: MLB_MARKET_SECONDARY,
  [Sport.NBA]: NBA_SECONDARY_MARKET,
};

export const INSTANT_BUY_URL: Record<Sport, string> = {
  [Sport.BASEBALL]: MLB_MARKET_INSTANT_BUY,
  [Sport.NBA]: NBA_INSTANT_BUY_MARKET,
  [Sport.FOOTBALL]: FOOTBALL_MARKET_INSTANT_BUY,
};

export const MY_CLUB_BY_SPORT: Record<Sport, string> = {
  [Sport.FOOTBALL]: FOOTBALL_MY_CLUB_SLUG,
  [Sport.BASEBALL]: MLB_MY_CLUB_SLUG,
  [Sport.NBA]: NBA_MY_CLUB_SLUG,
};

const SHARED_PAGES = [
  LANDING,
  MLB,
  catchAll(SETTINGS),
  MY_SORARE_WILDCARD,
  CAREERS,
  catchAll(LP),
  PRESS,
  TERMS_AND_CONDITIONS,
  LICENSED_PARTNERS,
  GAME_RULES,
  PRIVACY_POLICY,
  COOKIE_POLICY,
  CONFIRM_EMAIL,
  CONFIRM_DEVICE,
  DEBUG_DEVICE,
  catchAll(INVITE),
  R_USERSLUG,
  MOBILE_SIGN_UP,
  OAUTH_AUTORIZE,
  ACTIVITY_WILDCARD,
  BLOG_WILDCARD,
  BLOG_PATH,
  HELP_WILDCARD,
];

export function sharedAcrossSportsPage(pathname: string) {
  return !!SHARED_PAGES.find(page => matchPath(page, pathname));
}

export function isMlbPage(pathname: string) {
  return !!matchPath(catchAll(MLB), pathname);
}

export function isNBAPage(pathname: string) {
  return !!matchPath(NBA_WILDCARD, pathname);
}

// this should stay a hook for future feature flags integration
export const useDefaultSportPages = (
  getLoggedInVariant?: boolean
): { [key in Sport]: string } => {
  const {
    flags: { useMlbOffseason = false },
  } = useFeatureFlags();

  const mlbDefaultPage = useMlbOffseason ? MLB_PLAY : MLB_HOME;
  return useMemo(
    () => ({
      [Sport.FOOTBALL]: getLoggedInVariant ? FOOTBALL_PLAY : FOOTBALL,
      [Sport.NBA]: getLoggedInVariant ? NBA_PLAY : NBA,
      [Sport.BASEBALL]: getLoggedInVariant ? mlbDefaultPage : MLB,
    }),
    [getLoggedInVariant, mlbDefaultPage]
  );
};

export const RAMP_TRANSACTION_WEBHOOK = `${API_ROOT}/webhooks/ramp/transaction_state_updated`;
